import React from 'react';
import { Heading, Paragraph } from '../ui';
import Layout from '../layout/primary';

export default function() {
  return (
    <Layout title="404: Not found">
      <Heading>Not Found</Heading>
      <Paragraph>You just hit a route that doesn’t exist... the sadness.</Paragraph>
    </Layout>
  );
}
